@font-face {
  font-family: 'JikiTourai';
  src: local('JikiTourai'), url('../fonts/JikiTourai.ttf') format('truetype');
}

@font-face {
  font-family: 'S.A.O16';
  src: local('S.A.O16'), url('../fonts/S.A.O16.ttf') format('truetype');
}

@font-face {
  font-family: 'SometypeMono';
  src: local('SometypeMono'), url('../fonts/SometypeMono.ttf') format('truetype');
}

@font-face {
  font-family: 'Nimbus';
  src: local('Nimbus'), url('../fonts/Nimbus.otf') format('truetype');
}

 
@font-face {
  font-family: '28days';
  src: local('28days'), url('../fonts/28Days.ttf') format('truetype');
}
 
@font-face {
  font-family: 'Portmanteau';
  src: local('Portmanteau'), url('../fonts/Portmanteau.ttf') format('truetype');
} 
@font-face {
  font-family: 'scratched';
  src: local('scratched'), url('../fonts/scratched.ttf') format('truetype');
}