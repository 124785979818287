.logo img{
    max-height: 60px!important;
}

.button-main{
    background-color: #E1E289!important;
}

.JikiTourai{
    font-family: 'JikiTourai';
}

.SAO16{
    font-family: 'S.A.O16';
}

.SometypeMono{
    font-family: 'SometypeMono';

}
.Nimbus{
    font-family: 'Nimbus';

}
.Beograd{
    font-family: 'Beograd';
    
}
 
.scratched{
    font-family: 'scratched';

}
p{
    font-family: 'SometypeMono';

}
.title{
    font-family: '28days';

}
 
.rs-panel{
    background-color: #161924;
    border-radius: 30px;
}

.dv{
    span{
        font-family: 'JikiTourai';
        text-wrap: nowrap;
    }

    svg{
        height: 35px;
        width: 35px;
    }
    
}

.cards{
    .views{
        svg{
            height: 22px;
            width: 22px;
        }
    }
}
.button-like{
    background-color: rgb(255, 109, 109)!important;
    
    padding: 15px;
    svg{
        height: 20px;
        width: 20px;
        color: rgb(255, 255, 255);

    }
}
.liked{
    svg{
        color: rgb(201, 27, 27);
    }
}

.button-main {
    padding: 15px;

    @media (max-width: 768px) {
        padding: 8px;
    }
}

.button-share{
    padding: 15px;
    background-color: rgb(44, 44, 194)!important;
    svg{
        height: 20px;
        width: 20px;
        :hover{
            color: white!important;
        }
        :focus{
            color: white!important;

        }
    }
    :hover{
        color: white!important;
    }
    :focus{
        color: white!important;

    }
     
}
.link{
     text-decoration: none;
}
.nodec{
    text-decoration: none;
    :hover{
        color: white;
        text-decoration: none;
        
    }
}
a{
    background-color: inherit!important;
}
.text-7xl{
    font-size: clamp(2.1rem, 5vw, 3rem)!important;
}
.mailing{
    background-color: #13161d!important;
}
.home-videoplayer{
    @media (max-width: 768px) {
        filter: brightness(0.15)!important;
    }
}
.cCardc{
    .rs-panel-body{
        padding-top: 0px!important;
    }
}
.header{
    a{
        text-decoration: none;
        color: white;
        :hover{
            text-decoration: underline;
        }
        font-size: clamp(1rem, 2vw, 1.2rem)!important;
    }
}
 

.menuicon svg {
    height: 25px;
    width: 25px;
    transition: transform 0.3s ease;
    transform: rotate(0deg);
    
  }
  
  .menuicon.rotate svg {
    transform: rotate(180deg);
  }
  
  .mobilenav {
    transition: max-height 0.3s ease;
    max-height: 0;
    overflow: hidden;
  }
  
  .mobilenav.open {
    max-height: 500px; /* Adjust as needed */
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}