@tailwind base;
@tailwind components;
@tailwind utilities;
*{
  font-family: 'Inter', sans-serif;
}
.z{
  position: absolute;  
  top: 50%;
  left: 43%;
  transform: translate(-50%, -50%);
  color: white;
  line-height: 86px;
  font-size: 6rem;
  /* font-weight: 1000!important; */
}
.home-videoplayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* filter: brightness(0.5); */
  object-fit: cover;
}
.logo{
  z-index: 999;
  position: fixed;
  width: 100%;
}
 
.rot{
  rotate: 1deg;
  transform: translate(-10px,-50px);
  background-color: #161924;
}
.footer{
  background-color: #0a0c12;
}

.social-link a
{
  
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#33353d;
  transition: 0.1s ease-out;

}
 
.social-link svg{
  color: white;
  height: 20px;
  width: 20px;
}

.social-link a:hover
{
  background-color: #29aafe;
  transition: 0.1s ease-in;
}
.links{
  display: flex;
  justify-content: center;
  align-items: center;
}
.item-link{
  display: block;
  width: auto;
  height: auto;
  color: white;
}
.item-link a{
  
  color: white;
}
#header {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  background-color: #0a0c12;
}

#header.show {
  opacity: 1;
}
.titletext{
  font-size: clamp(30px, 5vw, 20px);
  color: white;
  font-weight: 900;
}
.flip{
  scale: -1;
}



.image-container {
  position: relative;
  width: 200px; /* Adjust as needed */
}

.image-container img {
  width: 100%;
  transition: filter 0.5s ease;
}

.image-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transform: scale(1.2);
}

.image-container:hover img {
  filter: brightness(50%);
}

.image-container:hover .overlay {
  opacity: 1;
  transform: scale(1);
}
.chimp svg{
  height: 190px;
  width: 190px;
  color: white;
  font-weight: 100;
}